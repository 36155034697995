import { createSlice } from "@reduxjs/toolkit";

const token = localStorage.getItem("token");

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: token || "",
    radarKey: ""
  },
  reducers: {
    login(state, action) {
      state.token = action.payload;

    },
    setRadarKey(state, action) {
      state.radarKey = action.payload
    },
    logout(state) {
      state.token = "";
    },
  },
});

export const { login, logout, setRadarKey } = authSlice.actions;

export default authSlice.reducer;
