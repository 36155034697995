import { Button } from '@mui/material';
import React from 'react'
import * as XLSX from 'xlsx';

const DownloadExcelBtn = ({ data, sheetName, excelData }) => {


    const downloadExcel = () => {
        const wb = XLSX.utils.book_new();
        const { eData, columnWidths } = excelData(data)
        const ws = XLSX.utils.json_to_sheet(eData);
        ws['!cols'] = columnWidths;
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, `${sheetName}.xlsx`);
    }


    return (
        data?.length > 0 ?
            <Button
                type="button"
                variant="contained"
                onClick={downloadExcel}
                style={{ marginRight: "20px" }}
            >
                Export
            </Button>
            : ""
    )
}

export default DownloadExcelBtn