import React from 'react'
import Report from './Report'
import { ReportServices } from 'src/api/reportServices'
import { formatDate, getSrno } from 'src/utils/helpers'
import { Avatar } from '@material-ui/core'
import { CurrentDateFormatWithTime, FILE_URL } from 'src/utils/constants'
import moment from 'moment'

const CouponPurchaseReport = () => {
    const getColumns = (pageNumber, pageSize) => [
        {
            name: "Sr. No.",
            selector: (row, index) => getSrno(pageNumber, pageSize, index),
            grow: 0.4
        },
        {
            name: "Coupon Image",
            selector: (row) => <Avatar
                style={{ height: "50px", width: "50px", margin: "5px 0px" }}
                alt={"coupon image"}
                src={FILE_URL + row?.couponImage}
            />,
            grow: 0.8

        },
        {
            name: "Coupon Purchased Id",
            selector: (row) => row?.id,
            grow: 1.4
        },
        {
            name: "Title",
            selector: (row) => row?.title,
            sortable: true,
            sortField: "title"
        },
        {
            name: "Name",
            selector: (row) => row?.fullName,
            sortable: true,
            sortField: "fullName"
        },
        {
            name: "Business Name",
            selector: (row) => row?.businessName,
            sortable: true,
            sortField: "businessName"
        },
        {
            name: "Price",
            selector: (row) => row?.price,
            sortable: true,
            sortField: "price",
            grow: 0.5
        },
        {
            name: "Quantity",
            selector: (row) => row?.qty,
            grow: 0.5
        },
        {
            name: "Duration",
            selector: (row) => `${row?.duration} ${row?.durationText}`,
            grow: 0.7
        },
        {
            name: "Purchased Date",
            selector: (row) => formatDate(row?.purchaseDateTime, CurrentDateFormatWithTime),
            sortable: true,
            sortField: "purchaseDateTime"
        },
        {
            name: "Slot Start Time",
            selector: (row) => moment.utc(row?.slotStartTime, "HH:mm:ss").local().format("h:mm A"),
        },

    ]


    const getexcelData = (data) => {
        let temp = data?.map((d) => {
            return {
                "Title": d?.title,
                "Name": d?.fullName,
                "Business Name": d?.businessName,
                "Price": d?.price,
                "Quantity": d?.qty,
                "Duration": `${d?.duration} ${d?.durationText}`,
                "Purchased Date": formatDate(d?.purchaseDateTime, CurrentDateFormatWithTime),
                "Slot Start Time": moment.utc(d?.slotStartTime, "HH:mm:ss").local().format("h:mm A")
            }
        })
        const columnWidths = [
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
        ];

        return { eData: temp, columnWidths }
    }


    return (
        <div>
            <Report
                title={"Coupon Purchased Report"}
                apiUrl={ReportServices.couponPurchasedReport}
                getColumns={getColumns}
                excelData={getexcelData}
                sheetName={"Coupon Purchased Report"}
            />
        </div>
    )
}

export default CouponPurchaseReport