import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import * as Yup from "yup"

const ShowRemarks = ({ show, setShow, handleStatus, loading }) => {

    const formik = useFormik({
        initialValues: {
            remarks: ""
        },
        validationSchema: Yup.object({
            remarks: Yup.string().required("Remark is required").trim()
        }),
        onSubmit: (values) => {
            handleStatus(values.remarks)
        }
    })


    const onHide = () => {
        if (!loading) {
            setShow(false)
        }
    }

    return (
        <Dialog maxWidth="md" fullWidth={true} open={show} onClose={onHide}>
            <DialogTitle as="h2">Add Remark</DialogTitle>

            <form onSubmit={formik.handleSubmit}>
                <DialogContent dividers>
                    <Grid container spacing={2} sx={{ overflowWrap: "anywhere" }} mb={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="remarks"
                                label="Remark"
                                {...formik.getFieldProps('remarks')}
                                error={Boolean(formik.touched.remarks && formik.errors.remarks)}
                                helperText={formik.touched.remarks && formik.errors.remarks}
                            />
                        </Grid>

                    </Grid>
                    <DialogActions>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={loading}
                        >
                            Submit
                        </Button>

                        <Button
                            variant="outlined"
                            onClick={() => onHide()}
                            type="button"
                            style={{ backgroundColor: "white" }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </DialogContent>
            </form>

        </Dialog>
    )
}

export default ShowRemarks