import React from 'react'
import EditorPage from 'src/components/EditorPage'

const DeleteUserPolicy = () => {
    return (
        <>
            <EditorPage
                slugname={"user-delete-policy"}
                pageName={"User Delete Account Policy"}
            />
        </>
    )
}

export default DeleteUserPolicy
