// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// sections
import { AppCurrentVisits, AppWebsiteVisits, AppWidgetSummary } from '../sections/@dashboard/app';
import errorHandler from 'src/utils/errorHandler';
import { useEffect, useState } from 'react';
import AxiosInterceptor from 'src/utils/AxiosInterceptor';
import { addToFixedIfDecimal, get7daydata, getLastSevenDates } from 'src/utils/helpers';
import { Loader } from 'src/components/CustomLoader';

export default function DashboardAppPage() {
  const theme = useTheme();
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})


  const fetchDashbaordData = async () => {
    try {
      setLoading(true)
      const res = await AxiosInterceptor().get("access/getDashboardDetails")
      setData(res?.data?.data)


    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchDashbaordData()
  }, [])



  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome
        </Typography>



        <Grid container spacing={3} >
          <Grid item xs={12} sm={6} md={4} >
            <AppWidgetSummary title="Total Registered Users" total={data?.totalUsers || 0} icon={'ep:user-filled'} navigateUrl={"/user"} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Total Registered Merchant" total={data?.totalMerchants || 0} color="success" icon={'ep:user-filled'} navigateUrl={"/merchant"} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Total Active User" total={(data?.totalActiveUsers) || 0} color="warning" icon={'ep:user-filled'} navigateUrl={"/user?filter=activeUsers"} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Total Active Merchant" total={data?.totalActiveMerchants || 0} color="warning" icon={'ep:user-filled'} navigateUrl={"/merchant?filter=activeMerchants"} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Totol Coupon Puchased" total={data?.totalPurchaseCoupons || 0} color="error" icon={'ic:outline-payment'} navigateUrl={"/coupon-purchased-report"} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Totol Payment Received" total={data?.totalPayment ? `$${addToFixedIfDecimal(data?.totalPayment)}` : 0} color="success" icon={'ic:outline-payment'} navigateUrl={"/payment-history-management"} />
          </Grid>

          <Grid item xs={12} >
            <AppWebsiteVisits
              title="Recent Activities"
              chartLabels={getLastSevenDates()}
              chartData={[
                {
                  name: 'Recent Purchased Coupon',
                  type: 'column',
                  fill: 'solid',
                  data: get7daydata(data?.recentPurchasedCoupons),
                },
                {
                  name: 'Recent Registered Merchant',
                  type: 'area',
                  fill: 'gradient',
                  data: get7daydata(data?.recentRegisterMerchants),
                },
                {
                  name: 'Recent Registered User',
                  type: 'line',
                  fill: 'solid',
                  data: get7daydata(data?.recentRegisterUsers),
                },
              ]}
            />

            

          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="User Report"
              chartData={[
                { label: 'Active Users', value: data?.totalActiveUsers || 0 },
                { label: 'Inactive Users', value: data?.totalUsers ? data?.totalUsers - data?.totalActiveUsers : 0 },


              ]}
              chartColors={[theme.palette.primary.main, theme.palette.secondary.main, theme.palette.warning.main, theme.palette.error.main]}
            />
          </Grid> */}
        </Grid>

      </Container>
    </>
  );
}
