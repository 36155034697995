import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import DeletedUserService from 'src/api/deletedUserService'
import CustomLoader from 'src/components/CustomLoader'
import SearchBtn from 'src/components/SearchBtn'
import TableFilter from 'src/components/shared/TableFilter'
import { CurrentDateFormatWithTime, DeletedUserStatus } from 'src/utils/constants'
import errorHandler from 'src/utils/errorHandler'
import { formatDate, getSrno } from 'src/utils/helpers'

const DeletedUserList = () => {
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState([])
    const [paginData, setPaginData] = useState({
        data: [],
        totalCount: 0,
    })
    const [payload, setPayload] = useState({
        pageSize: 5,
        pageNumber: 1,
        searchItem: "",

    })

    const fetchDeletedUserList = async () => {
        try {
            setLoading(true)
            payload.status = status
            let res = await DeletedUserService.list(payload)
            setPaginData({
                data: res?.data?.data?.result,
                totalCount: res?.data?.data?.totalRecords
            })

        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchDeletedUserList()
    }, [payload, status])


    const showStatus = (status) => {
        if (status == 2) {
            return "Deleted"
        }
        else if (status == 3) {
            return "Under Delete"
        }
    }


    const columns = [
        {
            name: "Sr. No.",
            selector: (row, index) => getSrno(payload.pageNumber, payload.pageSize, index),
        },
        {
            name: "User Id",
            selector: (row) => row?.id
        },
        {
            name: "Name",
            selector: (row) => row?.fullName
        },
        {
            name: "Email",
            selector: (row) => row?.email
        },
        {
            name: "Phone Number",
            selector: (row) => row?.phoneNumber
        },
        {
            name: "Status",
            selector: (row) => showStatus(row?.status)
        },
        {
            name: "Deleted Date",
            selector: (row) => formatDate(row?.updatedAt, CurrentDateFormatWithTime)
        },


    ]




    return (
        <Card>
            <CardHeader
                titleTypographyProps={{ variant: "h4" }}
                title="Deleted User Management"
            >
            </CardHeader>

            <Grid container display={"flex"} justifyContent={"flex-end"} alignItems={"end"} gap={2} >
                <TableFilter
                    data={DeletedUserStatus}
                    value={status}
                    setValue={setStatus}
                    filterName="Status"
                />  
                <SearchBtn payload={payload} setPayload={setPayload} />
            </Grid>

            <CardContent>
                <DataTable
                    columns={columns}
                    data={paginData?.data}
                    pagination
                    paginationServer
                    paginationTotalRows={paginData?.totalCount}
                    paginationPerPage={payload?.pageSize}
                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                    onChangePage={(page) => setPayload({ ...payload, pageNumber: page })}
                    onChangeRowsPerPage={(currentRowPerPage, currentPage) => {
                        setPayload({ ...payload, pageSize: currentRowPerPage, pageNumber: currentPage })
                    }}
                    progressPending={loading}
                    progressComponent={<CustomLoader />}



                />

            </CardContent>
        </Card>
    )
}

export default DeletedUserList