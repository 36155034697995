import React from 'react'
import Report from './Report'
import { formatDate, getSrno } from 'src/utils/helpers'
import { ReportServices } from 'src/api/reportServices'
import { CurrentDateFormatWithTime } from 'src/utils/constants'

const RevenueReport = () => {
    const getColumns = (pageNumber, pageSize) => [
        {
            name: "Sr. No.",
            selector: (row, index) => getSrno(pageNumber, pageSize, index)
        },
        {
            name: "Revenue Id",
            selector: (row) => row?.couponPublishId,
        },
        {
            name: "Title",
            selector: (row) => row?.title,
            sortable: true,
            sortField: "title"
        },
        {
            name: "Total Revenue",
            selector: (row) => `$${row?.totalRevenue}`,
            sortable: true,
            sortField: "totalRevenue",
        },
        {
            name: "Created At",
            selector: (row) => formatDate(row?.latestCreatedAt, CurrentDateFormatWithTime),
            sortable: true,
            sortField: "latestCreatedAt"
        },

    ]

    const getexcelData = (data) => {
        let temp = data?.map((d) => {
            return {
                "Title": d?.title,
                "Total Revenue": `$${d?.totalRevenue}`,
                "Created At": formatDate(d?.latestCreatedAt, CurrentDateFormatWithTime),
            }
        })
        const columnWidths = [
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
        ];

        return { eData: temp, columnWidths }
    }


    return (
        <div>
            <Report
                title={"Revenue Report"}
                apiUrl={ReportServices.revenueReport}
                getColumns={getColumns}
                excelData={getexcelData}
                sheetName={"Revenue Report"}
            />
        </div>
    )
}

export default RevenueReport