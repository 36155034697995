import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useParams } from 'react-router-dom'
import { MerchantPayoutServices } from 'src/api/merchantPayoutServices'
import CustomLoader from 'src/components/CustomLoader'
import { CurrentDateFormatWithTime } from 'src/utils/constants'
import errorHandler from 'src/utils/errorHandler'
import { formatDate, getMerchantPayoutStatus, getSrno } from 'src/utils/helpers'
import VisibilityIcon from "@mui/icons-material/Visibility";


const MerchantPayoutView = () => {
    const { merchantId, status } = useParams()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [show, setShow] = useState(false)
    const [viewData, setViewData] = useState({})

    const fetchMerchantById = async () => {
        try {
            setLoading(true)
            const response = await MerchantPayoutServices.getSingleMerchantPayout(merchantId, status);
            setData(response.data.data);

        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        if (merchantId) {
            fetchMerchantById()
        }
    }, [merchantId])


    const columns = [
        {
            name: "Sr. No.",
            selector: (row, index) => index + 1,
        },
        {
            name: "Merchant Payout Id",
            selector: (row) => row?.id
        },
        {
            name: "Business Name",
            selector: (row) => row?.businessName,
        },
        {
            name: "Address",
            selector: (row) => `${row?.address}, ${row?.address2 ? `${row?.address2},` : ""} ${row?.city}, ${row?.state}, ${row?.zipCode}`,
        },
        {
            name: "Phone Number",
            selector: (row) => row?.phoneNumber,
        },
        // {
        //     name: "Coupon Id",
        //     selector: (row) => row?.couponId,
        // },
        // {
        //     name: "Redeem Date",
        //     selector: (row) => formatDate(row?.redeemDate, CurrentDateFormatWithTime),
        // },
        {
            name: "Paid Date",
            selector: (row) => formatDate(row?.paidDate, CurrentDateFormatWithTime),
        },
        {
            name: "Amount",
            selector: (row) => `$${row?.amount}`,
        },
        {
            name: "Status",
            selector: (row) => getMerchantPayoutStatus(row?.status)
        },
        {
            name: "Action",
            // selector: (row) => <MerchantPayoutView id={row?.merchantId} />,
            selector: (row) => (
                <Tooltip title="View" placement="top">
                    <IconButton
                        color='primary'
                        onClick={() => {
                            setShow(true)
                            setViewData(row)
                        }}
                    >
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
            )
        },
    ]

    const onHide = () => {
        setShow(false)
        setViewData({})
    }

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{ variant: "h4" }}
                    title="Merchant Payout View"
                >
                </CardHeader>

                <CardContent>
                    <DataTable
                        columns={columns}
                        data={data}

                        progressPending={loading}
                        progressComponent={<CustomLoader />}


                    />

                </CardContent>

            </Card>

            {show ?
                <Dialog maxWidth="md" fullWidth={true} open={show} onClose={onHide}>
                    <DialogTitle as="h2">Details</DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={2} sx={{ overflowWrap: "anywhere" }}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Business Name</Typography>
                                <DialogContentText>
                                    {viewData?.businessName}
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Address</Typography>
                                <DialogContentText>
                                    {viewData?.address}, {viewData?.address2 ? `${viewData?.address2},` : ""} {viewData?.city}, {viewData?.state}, {viewData?.zipCode}
                                </DialogContentText>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Phone Number</Typography>
                                <DialogContentText>
                                    {viewData?.phoneNumber}
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Coupon Id</Typography>
                                <DialogContentText>
                                    {viewData?.couponId}
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Paid Date</Typography>
                                <DialogContentText>
                                    {formatDate(viewData?.paidDate, CurrentDateFormatWithTime)}
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Redeem Date</Typography>
                                <DialogContentText>
                                    {formatDate(viewData?.redeemDate, CurrentDateFormatWithTime)}
                                </DialogContentText>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Amount</Typography>
                                <DialogContentText>
                                    ${viewData?.amount}
                                </DialogContentText>
                            </Grid>

                            <Grid item xs={12} md={6} >
                                <Typography variant="subtitle1">Status</Typography>
                                <DialogContentText>

                                    {getMerchantPayoutStatus(viewData?.status)}
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <Typography variant="subtitle1">Remark</Typography>
                                <DialogContentText>
                                    {viewData?.remarks}
                                </DialogContentText>
                            </Grid>

                        </Grid>
                        <DialogActions>

                            <Button
                                variant="outlined"
                                onClick={() => onHide()}
                                type="button"
                                style={{ backgroundColor: "white" }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </DialogContent>

                </Dialog>
                : ""}
        </>

    )
}

export default MerchantPayoutView