import { EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, Card, CardContent, CardHeader, FormControlLabel, FormGroup, IconButton, Switch, Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import apiUsers from "src/api/usersService";
import CustomLoader from "src/components/CustomLoader";
import { SearchBtn2 } from "src/components/SearchBtn";
import TableFilter from "src/components/shared/TableFilter";
import { tostE, tostS } from "src/utils/Toast";
import { CurrentDateFormatWithTime, STATUS_OPTIONS } from "src/utils/constants";
import { capitalize, formatDate, getSrno } from "src/utils/helpers";
import ViewUserDetail from "./ViewUser";

function UserManagement() {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [modalShow, setModalShow] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [statusId, setStatusId] = useState("");
  const [viewId, setViewId] = useState("");
  const [editId, setEditId] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [filteredData, setFilteredData] = useState("");
  const [warning, setWarning] = useState("");
  const [action, setAction] = useState("");
  const [refresh, setRefresh] = useState(false)
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(true)

  const params = new URLSearchParams(window.location.search)
  const filter = params.get("filter")


  const userData = async () => {

    const body = {
      searchItem: searchItem ? searchItem.trim() : "",
      pageNumber: currentPage,
      pageSize: pageSize,
      status: status,
    };
    setLoading(true)
    await apiUsers
      .getAllUser(body)
      .then((res) => {
        setData(res.data.data.result);
        setTotalPages(res.data.data.totalRecords);
      })
      .catch((err) => {
        setData("");
        setTotalPages("");
      })
      .finally(() => {
        setLoading(false)
      })
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const toggleChecked = async (id, statusChange) => {

    const res = await apiUsers.changeStatus({ id, status: statusChange == 1 ? 0 : 1 });

    setCurrentStatus("");
    setCheck(!check);

    if (res.status == 200) {
      tostS(res?.data?.message);
      setShow(false);
      setRefresh(!refresh);
    } else {
      tostE(res?.data?.message);
      setShow(false);
    }
  };

  useEffect(() => {
    userData();
  }, [searchItem, currentPage, pageSize, refresh, status]);


  const handleClose = () => setShow(false);

  useEffect(() => {
    if (filter == "activeUsers") {
      setStatus([1])
    }
  }, [filter])


  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => getSrno(currentPage, pageSize, index),
      grow: 0.5
    },
    {
      name: "User Id",
      selector: (row) => row?.id
    },
    {
      name: "Name",
      sortable: true,
      selector: (row) => (row?.fullName ? capitalize(row?.fullName) : "-"),
    },
    {
      name: "Email",
      sortable: true,
      selector: (row) => (row?.email ? (row?.email) : "-"),
    },
    {
      name: "Phone Number",
      sortable: true,
      selector: (row) => row.phoneNumber ? row.phoneNumber : "-",
    },
    {
      name: "Created At",
      selector: (row) => formatDate(row.createdAt, CurrentDateFormatWithTime),
      sortable: true,
      // selector: (row) => (row.createdAt ? moment(row.createdAt).format("DD-MMM-yyyy hh:mm a") : "-"),
    },
    {
      name: "Modified At",
      selector: (row) => formatDate(row.updatedAt, CurrentDateFormatWithTime),
      // selector: (row) => (row.createdAt ? moment(row.updatedAt).format("DD-MMM-yyyy hh:mm a") : "-"),
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={row.status == 1}
                  onClick={() => {
                    setShow(true);
                    setCurrentStatus(row.status);
                    setStatusId(row.id);
                    setAction("status");
                    setWarning("Are you sure want to change the status? ");
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </FormGroup>
        </>
      ),
    },
    {
      name: <div style={{ textAlign: "center", marginLeft: "40px" }}>Action</div>,
      cell: (row) => (
        <>
          <Tooltip title="Edit" placement="left">
            <IconButton
              color="primary"
              onClick={() => {
                setEditId(row.id);
                navigate(`/user/edit/${row.id}`);
              }}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>

          <Tooltip title="View" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setModalShow(true);
                setViewId(row.id);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="right">
            <IconButton
              color="primary"
              onClick={() => {
                setShow(true);
                setAction("delete");
                setStatusId(row.id);
                setWarning("Are you sure want to delete the user?");
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const handleDelete = async (id) => {
    const res = await apiUsers.deleteUser(id);

    if (res.status == 200) {
      tostS(res?.data?.message);
      setShow(false);
      setRefresh(!refresh)
    } else {
      tostE(res?.data?.message);
      setShow(false);
    }
  }

  return (
    <Card>
      <CardHeader titleTypographyProps={{ variant: "h4" }} title="User Management"></CardHeader>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <TableFilter
          data={STATUS_OPTIONS}
          value={status}
          setValue={setStatus}
          filterName="Status"
        />
        <SearchBtn2 search={searchItem} setSearch={setSearchItem} />
      
        <Button size="large" type="button" variant="contained" sx={{ ml: 3, mr: 3 }} onClick={() => navigate("/user/add")}>
          Add
        </Button>
      </Box>

      <CardContent>
        <DataTable
          columns={columns}
          data={filteredData ? filteredData : data}
          pagination
          paginationServer
          paginationTotalRows={totalPages}
          onChangePage={handlePageChange}
          paginationPerPage={pageSize}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          // paginationTotalRows={data ? data.length : 0}
          paginationRowsPerPageOptions={[5, 10, 20, 50]}
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
            setPageSize(currentRowsPerPage);
            setCurrentPage(currentPage);
          }}
        />
      </CardContent>

      {modalShow ? <ViewUserDetail show={modalShow} onHide={() => setModalShow(false)} id={viewId} /> : ""}
      <Dialog open={show} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
        <DialogTitle as="h2">{warning}</DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            style={{ backgroundColor: "white" }}
            onClick={() => {
              if (action == "status") {
                toggleChecked(statusId, currentStatus);
              } else if (action == "delete") {
                handleDelete(statusId);
              }
            }}
          >
            Yes
          </Button>
          <Button variant="outlined" style={{ backgroundColor: "white" }} onClick={handleClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default UserManagement;
