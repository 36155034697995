import React from 'react'
import EditorPage from 'src/components/EditorPage'

const RevenueShareTerms = () => {
    return (
        <>
            <EditorPage
                slugname={"merchant-revshare-terms"}
                pageName={"Revenue Share Terms "}
            />
        </>
    )
}

export default RevenueShareTerms