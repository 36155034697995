
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Radar from 'radar-sdk-js';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';

const GoogleAutoCompleteComp = ({ formik }) => {
    const [query, setQuery] = useState(formik.values.address || '');
    const [suggestions, setSuggestions] = useState([]);
    const [addressSelected, setAddressSelected] = useState(false);
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [loading, setLoading] = useState(false)
    const { radarKey } = useSelector(state => state.auth)

    const inputRef = useRef(null);
    const suggestionsRef = useRef(null);


    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedQuery(query);
        }, 500); // Adjust the delay as needed (e.g., 500 milliseconds)

        return () => {
            clearTimeout(timer);
        };
    }, [query]);

    useEffect(() => {
        if (radarKey) {
            Radar.initialize(radarKey);
        }
        return () => {
            if (radarKey)
                Radar.setUserId(null);
        };
    }, [radarKey]);

    useEffect(() => {
        const fetchAutocompleteSuggestions = async (address) => {
            try {
                setLoading(true)
                const responseUS = await axios.get('https://api.radar.io/v1/search/autocomplete', {
                    params: {
                        countryCode: 'US',
                        query: address,
                        limit: 5,
                    },
                    headers: {
                        Authorization: radarKey
                    }
                })

                setSuggestions(responseUS?.data?.addresses);
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.error('Error fetching autocomplete suggestions:', error);
            }
        };

        if (debouncedQuery) {
            fetchAutocompleteSuggestions(debouncedQuery);
        } else {
            setSuggestions([])
        }

    }, [debouncedQuery]);

    const handleInputChange = (event) => {
        setQuery(event.target.value);
        formik.setFieldValue("address", event.target.value);
        formik.setFieldValue("lat", "");
        formik.setFieldValue("lng", "");
        setSuggestions([])
        setAddressSelected(false);
    };

    const handleSuggestionClick = async (address) => {

        handlePlaceChanged(address);
    };

    const handlePlaceChanged = async (place) => {
        formik.setFieldValue("lat", place?.latitude)
        formik.setFieldValue("lng", place?.longitude)
        formik.setFieldValue("address", place?.formattedAddress)
        setAddressSelected(true)
        setSuggestions([])
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
                // Click occurred outside the suggestions box
                setSuggestions([]);
            }
        };

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on cleanup
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className='address_con'>
            <TextField
                id="autocomplete"
                type="text"
                fullWidth
                label="Address"
                value={formik.values.address}
                onChange={handleInputChange}
                ref={inputRef}
                onBlur={() => {
                    formik.setFieldTouched("address", true)
                }}
                error={Boolean(formik.touched.address && formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
            />
            {suggestions?.length > 0 && !addressSelected && (
                <ul ref={suggestionsRef} className='suggestion-box'>
                    {suggestions.map((address, index) => (
                        <li className='suggestion-box-list' key={index} onClick={() => handleSuggestionClick(address)}>
                            {address?.formattedAddress}
                        </li>
                    ))}
                </ul>
            )}

            {loading ? <ul ref={suggestionsRef} className='suggestion-box'>
                <li>Loading...</li>
            </ul> : ""}
        </div>
    )
}

export default GoogleAutoCompleteComp;
