import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'


const FilterByUser = ({ userId, setUserId, label, allUserList, keyName }) => {

    return (
        <div className="filter_con">
            <FormControl variant="standard" className='filter_wrp'>
                <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={userId}
                    onChange={e => setUserId(e.target.value)}

                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {allUserList?.map((user, id) => (
                        <MenuItem value={user?.id} key={id}>{keyName ? user?.[keyName] : user?.fullName}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}

export default FilterByUser