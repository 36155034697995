import React from 'react'
import EditorPage from 'src/components/EditorPage'

const HybridTerms = () => {
    return (
        <>
            <EditorPage
                slugname={"merchant-hybrid-terms"}
                pageName={"Hybrid Terms"}
            />
        </>
    )
}

export default HybridTerms