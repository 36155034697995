import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Stack,
    Grid,
    Typography,
    FormLabel,
    TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { Form, FormikProvider, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import cmsService from "src/api/cmsService";
import errorHandler from "src/utils/errorHandler";
import EdiorJodit from "./editor/editor";
import { Loader } from "./CustomLoader";
import { tostS } from "src/utils/Toast";


const EditorPage = ({ slugname, pageName }) => {
    const [loading, setLoading] = useState(true)

    const formik = useFormik({
        initialValues: {
            id: "",
            description: "",
        },
        onSubmit: (values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting)
        }
    })

    const handleSubmit = async (values, setSubmitting) => {
        try {
            let payload = {
                id: values.id,
                description: values?.description,
                slug: slugname,
            }
            let res = await cmsService.updateCms(payload);
            tostS(res?.data?.message);

        } catch (error) {
            errorHandler(error)
        }finally{
            setSubmitting(false)
        }
    }


    const fetchTerms = async () => {
        try {
            let params = {
                slug: slugname,
            };
            setLoading(true)
            const resp = await cmsService.getOneCms(params);
            formik.setFieldValue("description", resp?.data?.data?.description);
            formik.setFieldValue("id", resp?.data?.data?.id);
        } catch (error) {
            errorHandler(error);
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchTerms();
    }, []);



    return (
        <>
            <Container>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        {pageName}
                    </Typography>
                </Stack>

                <Container>
                    <Card>
                        <CardContent>
                            {loading
                                ? <Loader />
                                : <FormikProvider value={formik}>
                                    <Form
                                        autoComplete="off"
                                        noValidate
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <Grid item xs={12} md={6}>
                                            <FormLabel sx={{ display: "block" }}>Description</FormLabel>

                                            <EdiorJodit
                                                onChange={(html) => {
                                                    formik.setFieldValue("description", html);
                                                }}
                                                data={formik.values.description}
                                                placeholder="Privacy Policy"
                                            />
                                        </Grid>
                                        <Stack
                                            spacing={2}
                                            mt={5}
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="flex-end"
                                        >
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                disabled={formik.isSubmitting}
                                                startIcon={<Icon icon="fa-solid:save" />}
                                            >
                                                Save
                                            </Button>
                                        </Stack>
                                    </Form>
                                </FormikProvider>
                            }
                        </CardContent>
                    </Card>
                </Container>
            </Container>
        </>
    )
}

export default EditorPage