import React from 'react'
import Report from './Report'
import { ReportServices } from 'src/api/reportServices'
import { formatDate, getSrno } from 'src/utils/helpers'
import { CurrentDateFormatWithTime } from 'src/utils/constants'

const UserRegisterReport = () => {
    const getColumns = (pageNumber, pageSize) => [
        {
            name: "Sr. No.",
            selector: (row, index) => getSrno(pageNumber, pageSize, index)
        },
        {
            name: "User Id",
            selector: (row) => row?.id
        },
        {
            name: "Name",
            selector: (row) => row?.fullName,
            sortable: true,
            sortField: "fullName"

        },
        {
            name: "Email",
            grow: 2,
            selector: (row) => row?.email,
            sortable: true,
            sortField: "email"
        },
        {
            name: "Phone Number",
            selector: (row) => row?.phoneNumber,
            sortable: true,
            sortField: "phoneNumber"
        },
        {
            name: "Status",
            selector: (row) => row?.status == "0" ? "Inactive" : "Active",
            sortable: true,
            sortField: "status"
        },
        {
            name: "Registered Date",
            selector: (row) => formatDate(row?.createdAt, CurrentDateFormatWithTime),
            sortable: true,
            sortField: "createdAt"
        },
    ]

    const getexcelData = (data) => {
        let temp = data?.map((d) => {
            return {
                "Name": d?.fullName,
                "Email": d?.email,
                "Phone Number": d?.phoneNumber,
                "Status": d?.status == "0" ? "Inactive" : "Active",
                "Registered Date": formatDate(d?.createdAt, CurrentDateFormatWithTime),
            }
        })
        const columnWidths = [
            { wch: 15 }, // Width for column A
            { wch: 30 }, // Width for column B
            { wch: 15 }, // Width for column C
            { wch: 15 }, // Width for column C
            { wch: 15 }, // Width for column C
        ];

        return { eData: temp, columnWidths }
    }

    return (
        <Report
            title={"User Register Report"}
            apiUrl={ReportServices.userReport}
            getColumns={getColumns}
            excelData={getexcelData}
            sheetName={"User Register Report"}
        />
    )
}

export default UserRegisterReport