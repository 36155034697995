import React from 'react'
import EditorPage from 'src/components/EditorPage'

const SubscriptionTerms = () => {
    return (
        <>
            <EditorPage
                slugname={"merchant-subscription-terms"}
                pageName={"Subscription Terms"}
            />
        </>
    )
}

export default SubscriptionTerms