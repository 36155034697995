

import { EditOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Card, CardContent, CardHeader, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import errorHandler from "src/utils/errorHandler";
// import { changeStatusApi, getAllPublishedCoupon } from "src/api/couponTemplateServices";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import couponTemplateServices, { changeStatusApi } from "src/api/couponTemplateServices";
import CustomLoader from "src/components/CustomLoader";
import SearchBtn from "src/components/SearchBtn";
import TableFilter from "src/components/shared/TableFilter";
import SwitchBtn from "src/components/SwitchBtn";
import { FILE_URL, STATUS_OPTIONS } from "src/utils/constants";
import { formatDate, getSrno } from "src/utils/helpers";
import View from "./View";



const CouponTemplateList = () => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [paginData, setPaginData] = useState({
        data: [],
        totalCount: 0,
    })
    const [payload, setPayload] = useState({
        pageSize: 5,
        pageNumber: 1,
        searchItem: "",

    })
    const [status, setStatus] = useState([]);
    const [viewModal, setViewModal] = useState({
        show: false,
        id: ""
    }
    )
    const [sort, setSort] = useState({
        sortBy: "",
        asc: 1   // 1 == acs 0 == desc
    })

    const fetchCouponList = async () => {
        try {
            setLoading(true)

            payload.status = status;
            payload.sortBy = sort.sortBy;
            payload.sortOrder = sort.asc;

            let res = await couponTemplateServices.getAllPublishedCoupon(payload)
            setPaginData({
                data: res?.data?.data?.result,
                totalCount: res?.data?.data?.totalRecords
            })

        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchCouponList()
    }, [payload, status, sort])

    const handleSort = (e, sortDirection) => {
        setSort({
            sortBy: e.sortField,
            asc: sortDirection
        })
    }


    const showHours = (durationText, duration) => {
        if (durationText == "Hours") {
            return `${duration / 60} ${durationText}`
        } else {
            return `${duration} ${durationText}`
        }
        // `${row?.duration} ${row?.durationText}`
    }

    const columns = [
        {
            name: "Sr. No.",
            selector: (row, index) => getSrno(payload.pageNumber, payload.pageSize, index),
            grow: 0.5
        },
        {
            name: "Coupon Image",
            selector: (row) =>
                <Avatar
                    style={{ height: "50px", width: "50px", margin: "5px 0px" }}
                    alt={"coupon image"}
                    src={FILE_URL + row?.couponImage}
                />
        },
        {
            name: "Coupon Template Id",
            selector: (row) => row?.id,
            grow: 1.3
        },
        {
            name: "Title",
            selector: (row) => row?.title,
            sortable: true,
            sortField: 'title',
        },
        {
            name: "Merchant Name",
            selector: (row) => row?.businessName,
            sortable: true,
            sortField: 'businessName',
        },
        {
            name: "List Price",
            selector: (row) => `$${row?.price?.toFixed(2)}`,
            sortable: true,
            sortField: 'price',
        },
        {
            name: "Discount Percentage",
            selector: (row) => row?.disPercentage,
            sortable: true,
            sortField: 'disPercentage',
        },
        {
            name: "Duration",
            selector: (row) => showHours(row?.durationText, row?.duration)
            // selector: (row) => `${row?.duration} ${row?.durationText}`,
        },

        {
            name: "Created At",
            selector: (row) => formatDate(row?.createdAt),
            // selector: (row) => moment(row?.createdAt).format("DD/MM/YYYY"),
            sortable: true,
            sortField: 'createdAt',
        },
        {
            name: "Status",
            selector: (row) => <SwitchBtn status={row.status} id={row?.id} apiUrl={changeStatusApi} callBack={fetchCouponList} />,
            grow: 0.5
        },
        {
            name: <div style={{ textAlign: "center", marginLeft: "40px" }}>Action</div>,
            cell: (row) => (
                <>
                    <Tooltip title="Edit" placement="top">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                navigate(`/coupon-templates/edit/${row.id}`);
                            }}
                        >
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="View" placement="top" leaveDelay={50}>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setViewModal({
                                    show: true,
                                    id: row.id
                                })
                            }}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ]

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{ variant: "h4" }}
                    title="Coupon Templates"
                ></CardHeader>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    <TableFilter
                        data={STATUS_OPTIONS}
                        value={status}
                        setValue={setStatus}
                        filterName="Status"
                    />
                    <SearchBtn payload={payload} setPayload={setPayload} />

                    <Button
                        size="large"
                        type="submit"
                        variant="contained"
                        sx={{ ml: 3, mr: 3 }}
                        onClick={() => navigate("/coupon-templates/add")}
                    >
                        Add
                    </Button>
                </Box>

                <CardContent>
                    <DataTable
                        columns={columns}

                        data={paginData.data}
                        pagination
                        paginationServer
                        paginationTotalRows={paginData.totalCount}
                        onChangePage={(page) => setPayload({ ...payload, pageNumber: page })}
                        paginationPerPage={payload.pageSize}
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                        onSort={handleSort}
                        sortServer
                        // paginationTotalRows={data ? data.length : 0}
                        paginationRowsPerPageOptions={[5, 10, 20, 50]}
                        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                            setPayload({ ...payload, pageSize: currentRowsPerPage, pageNumber: currentPage });
                        }}

                    />
                </CardContent>
            </Card>

            {viewModal.show ?
                <View show={viewModal.show} onHide={() => setViewModal({ show: false, id: "" })} id={viewModal.id} />
                : null}
        </>
    )
}

export default CouponTemplateList