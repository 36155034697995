import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import { ErrorMessage, useFormik } from "formik";
import * as Yup from "yup"
import { handleNumberInput } from "src/utils/helpers";
import errorHandler from "src/utils/errorHandler";
import { approveMerchant } from "src/api/merchantServices";
import { toast } from "react-toastify";
import { SubscripitonPlansOptions } from "src/utils/constants";



const SubscripitonModal = ({ data, refresh, setRefresh }) => {
    const [show, setShow] = useState(false)


    const formik = useFormik({
        initialValues: {
            paymentModeType: "",
            sharePercentage: "",
            subscriptionPrice: "",
            subscriptionMonth: "1",
            hybridPrice: ""
        },
        validationSchema: Yup.object({
            paymentModeType: Yup.string().required("Subscription mode is requied"),
            sharePercentage: Yup.string().when('paymentModeType', {
                is: (paymentModeType) => paymentModeType == "revshare",
                then: () => Yup.number().required("Per Coupon Sold Fee is required").min(1, "Per Coupon Sold Fee must be greater than 0").max(100, "Per Coupon Sold Fee must be less than or equals to 100"),
                otherwise: () => Yup.string()
            }),
            subscriptionPrice: Yup.string().when('paymentModeType', {
                is: (paymentModeType) => paymentModeType == "subscription" || paymentModeType == "hybrid",
                then: () => Yup.number().required("Monthly fee is required").min(1, "Monthly fee must be greater than 0").max(999999.99, "Monthly fee must be less than or equals to 999999"),
                otherwise: () => Yup.string()
            }),
            hybridPrice: Yup.string().when("paymentModeType", {
                is: (paymentModeType) => paymentModeType == "hybrid",
                then: () => Yup.number().required("Per coupon fee is required").min(1, "Per coupon fee must be greater than 0").max(999999.99, "Per coupon fee must be less than or equals to 999999"),
                otherwise: () => Yup.string()

            })
        }),
        onSubmit: (values, { setSubmitting }) => {
            handleSubmit({ ...values }, setSubmitting)
        }
    })

    const handleClose = () => {
        if (!formik.isSubmitting) {
            setShow(false)
            formik.resetForm()
        }
    }





    const handleSubmit = async (values, setSubmitting) => {
        try {
            if (values.paymentModeType == "revshare") {
                delete values.subscriptionMonth
                delete values.subscriptionPrice
                delete values.hybridPrice
            }
            else if (values.paymentModeType == "subscription") {
                delete values.sharePercentage
                delete values.hybridPrice
            }
            else if (values.paymentModeType == "hybrid") {
                delete values.sharePercentage
            }
            const res = await approveMerchant(data?.id, values);
            toast.success(res?.data?.message, {
                toastId: "merchant_approved"
            })
            setRefresh(!refresh)
            setShow(false)
            formik.resetForm()

        } catch (error) {
            console.log("error", error)
            errorHandler(error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <>
            <Tooltip title="Approve" placement="bottom">
                <IconButton
                    color="primary"
                    onClick={() => {
                        setShow(true);
                    }}
                >
                    <CheckIcon />
                </IconButton>
            </Tooltip>
            <Dialog maxWidth="md" fullWidth={true} open={show} onClose={handleClose}>
                <DialogTitle as="h2">Subscription Details</DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent dividers>
                        <Grid container spacing={2} mb={3} sx={{ overflowWrap: "anywhere" }} >
                            <Grid item container xs={12} alignItems={"center"} >
                                <Grid item xs={3}>
                                    <Typography>Subscripton Mode :</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label" error={formik.touched.paymentModeType && Boolean(formik.errors.paymentModeType)}>Subscription Mode</InputLabel>

                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Subscription Mode"
                                            value={formik.values.paymentModeType}
                                            error={formik.touched.paymentModeType && Boolean(formik.errors.paymentModeType)}
                                            helperText={formik.touched.paymentModeType && Boolean(formik.errors.paymentModeType)}
                                            onChange={(e) => formik.setFieldValue("paymentModeType", e?.target.value)}
                                        >
                                            {SubscripitonPlansOptions?.map((sub, id) => (
                                                <MenuItem value={sub?.value} key={id}>{sub?.label}</MenuItem>
                                            ))}

                                        </Select>
                                        <FormHelperText className="error-msg">{formik?.touched?.paymentModeType && formik?.errors?.paymentModeType ? formik?.errors?.paymentModeType : ""}</FormHelperText>


                                    </FormControl>
                                </Grid>

                            </Grid>

                            {
                                (formik.values.paymentModeType == "revshare") ?
                                    <Grid item container xs={12} alignItems={"center"} >
                                        <Grid item xs={3}>
                                            <Typography>Per Coupon Sold Fee (%) :</Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <TextField
                                                fullWidth
                                                label="Per Coupon Sold Fee (%)"
                                                variant="outlined"
                                                {...formik.getFieldProps("sharePercentage")}
                                                onChange={e => handleNumberInput(e, "sharePercentage", formik, true)}
                                                error={formik.touched.sharePercentage && Boolean(formik.errors.sharePercentage)}
                                                helperText={formik.touched.sharePercentage && formik.errors.sharePercentage}

                                            />

                                        </Grid>
                                    </Grid>
                                    : ""
                            }

                            {
                                (formik.values.paymentModeType == "subscription" || formik.values.paymentModeType == "hybrid") ?
                                    <>
                                        <Grid item container xs={12} alignItems={"center"} >
                                            <Grid item xs={3}>
                                                <Typography>Time-frequency :</Typography>
                                            </Grid>

                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    label="Time-frequency"
                                                    variant="outlined"
                                                    value={"1 Month"}
                                                    disabled

                                                />
                                            </Grid>
                                        </Grid>

                                    </>
                                    : ""
                            }

                            {
                                (formik.values.paymentModeType == "subscription") ?
                                    <>
                                        <Grid item container xs={12} alignItems={"center"} >
                                            <Grid item xs={3}>
                                                <Typography>Monthly Fee ($) :</Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    label="Monthly Fee ($)"
                                                    variant="outlined"
                                                    {...formik.getFieldProps("subscriptionPrice")}
                                                    onChange={e => handleNumberInput(e, "subscriptionPrice", formik, true)}
                                                    error={formik.touched.subscriptionPrice && Boolean(formik.errors.subscriptionPrice)}
                                                    helperText={formik.touched.subscriptionPrice && formik.errors.subscriptionPrice}

                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                    : ""
                            }


                            {
                                (formik.values.paymentModeType == "hybrid") ?
                                    <>
                                        <Grid item container xs={12} alignItems={"center"} >
                                            <Grid item xs={3}>
                                                <Typography>Monthly Fee ($) :</Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    label="Monthly Fee ($)"
                                                    variant="outlined"
                                                    {...formik.getFieldProps("subscriptionPrice")}
                                                    onChange={e => handleNumberInput(e, "subscriptionPrice", formik, true)}
                                                    error={formik.touched.subscriptionPrice && Boolean(formik.errors.subscriptionPrice)}
                                                    helperText={formik.touched.subscriptionPrice && formik.errors.subscriptionPrice}

                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={12} alignItems={"center"} >
                                            <Grid item xs={3}>
                                                <Typography>Per Coupon Sold Fee ($) :</Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    label="Per Coupon Sold Fee ($)"
                                                    variant="outlined"
                                                    {...formik.getFieldProps("hybridPrice")}
                                                    onChange={e => handleNumberInput(e, "hybridPrice", formik, true)}
                                                    error={formik.touched.hybridPrice && Boolean(formik.errors.hybridPrice)}
                                                    helperText={formik.touched.hybridPrice && formik.errors.hybridPrice}

                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                    : ""
                            }

                        </Grid>

                        <DialogActions >
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={formik.isSubmitting}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => handleClose()}
                                style={{ backgroundColor: "white" }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>

            </Dialog>
        </>
    )
}

export default SubscripitonModal