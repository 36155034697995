import { IconButton, Input, InputAdornment } from '@mui/material';
import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const SearchBtn = ({ payload, setPayload, label }) => {
    const [searchValue, setSearchValue] = useState(payload?.searchItem || '');

    useEffect(() => {
        const handler = setTimeout(() => {
            setPayload({ ...payload, searchItem: searchValue });
        }, 500); // 500ms debounce delay

        return () => {
            clearTimeout(handler);
        };
    }, [searchValue]);


    return (
        <Input
            label="Search"
            variant="standard"
            value={searchValue}
            placeholder={label || "Search"}
            onChange={(e) => setSearchValue(e.target.value)}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="clear-search"
                        onClick={() => {
                            if (payload.searchItem != "") {
                                setSearchValue('');
                                setPayload({ ...payload, searchItem: '' });
                            }
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </InputAdornment>
            }
        />
    );
};


const SearchBtn2 = ({ search, setSearch, label = "Search" }) => {
    const [searchValue, setSearchValue] = useState(search || '');

    useEffect(() => {
        const handler = setTimeout(() => {
            setSearch(searchValue);
        }, 500); // 500ms debounce delay

        return () => {
            clearTimeout(handler);
        };
    }, [searchValue]);


    return (
        <Input
            label="Search"
            variant="standard"
            value={searchValue}
            placeholder={label || "Search"}
            onChange={(e) => setSearchValue(e.target.value)}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="clear-search"
                        onClick={() => {
                            setSearchValue('');
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </InputAdornment>
            }
        />
    );
};

export default SearchBtn;
export { SearchBtn2 }