import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import React, { useState } from 'react'
import ConfirmModal from './confirmModal';
import errorHandler from 'src/utils/errorHandler';
import AxiosInterceptor from 'src/utils/AxiosInterceptor';
import { toast } from 'react-toastify';

const SwitchBtn = ({ status, id, apiUrl, callBack, rowData, merchantPage }) => {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)


    const handleSwitch = async () => {
        try {
            setLoading(true)
            let body = {
                id: id,
                status: status == 1 ? "0" : "1"
            }

            let res = await AxiosInterceptor().post(apiUrl, body)
            toast.success(res?.data?.message, {
                toastId: "change-status"
            })
            setShow(false)
            callBack()

        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }


    }

    const handleClick = () => {
        if (merchantPage) {
            if (rowData?.requestStatus == "0") {
                return toast.error("Please approve the merchant request first", {
                    toastId: "approveMerchantFirst"
                })
            } else {
                return setShow(true)
            }
        }
        setShow(true);

    }

    return (
        <>
            <FormGroup style={{ paddingLeft: "8px" }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={status == 1}
                            onClick={() => {
                                handleClick()
                            }}

                            inputProps={{ "aria-label": "controlled" }}
                        />
                    }
                />
            </FormGroup>
            {show && <ConfirmModal show={show} setShow={setShow} msg={"Are you sure want to change the status?"} handleSwitch={handleSwitch} loading={loading} />}
        </>
    )
}

export default SwitchBtn 