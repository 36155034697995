import { tostE } from "./Toast";

const errorHandler = (err) => {
    if (err.response?.data) {
        const { message, status, error } = err.response?.data;
        if (status === 0 && Array.isArray(error) && error.length > 0) {
            error.map((err) => tostE(err.msg))
            return
        }
        if (message) {
            tostE(message)
            return
        }

        tostE("Something went wrong!")
    } else {
        tostE('An error occurred')
    }
}

export default errorHandler