import React from 'react'
import Report from './Report'
import { ReportServices } from 'src/api/reportServices'
import { formatDate, getSrno } from 'src/utils/helpers'
import { CurrentDateFormatWithTime } from 'src/utils/constants'

const MerchantRegisterReport = () => {

    const getColumns = (pageNumber, pageSize) => [
        {
            name: "Sr. No.",
            selector: (row, index) => getSrno(pageNumber, pageSize, index),
        },
        {
            name: "Merchant Id",
            selector: (row) => row?.id
        },
        {
            name: "Business Name",
            selector: (row) => row?.businessName,
            sortable: true,
            sortField: "businessName"
        },
        {
            name: "Email",
            selector: (row) => row?.email,
            sortable: true,
            sortField: "email"
        },
        {
            name: "Phone Number",
            selector: (row) => row?.phoneNumber,
            sortable: true,
            sortField: "phoneNumber"
        },
        {
            name: "Status",
            selector: (row) => row?.status == "0" ? "Inactive" : "Active",
            sortable: true,
            sortField: "status"
        },
        {
            name: "Registered Date",
            selector: (row) => formatDate(row?.createdAt, CurrentDateFormatWithTime),
            sortable: true,
            sortField: "createdAt"
        },
    ]

    const getexcelData = (data) => {
        let temp = data?.map((d) => {
            return {
                "Business Name": d?.businessName,
                "Email": d?.email,
                "Phone Number": d?.phoneNumber,
                "Status": d?.status == "0" ? "Inactive" : "Active",
                "Registered Date": formatDate(d?.createdAt, CurrentDateFormatWithTime),
            }
        })
        const columnWidths = [
            { wch: 15 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
        ];

        return { eData: temp, columnWidths }
    }

    return (
        <div>
            <Report
                title={"Merchant Register Report"}
                apiUrl={ReportServices.merchantReport}
                getColumns={getColumns}
                excelData={getexcelData}
                sheetName={"Merchant Register Report"}
            />
        </div>
    )
}

export default MerchantRegisterReport