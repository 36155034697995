import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, Card, CardContent, Chip, FormControl, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { EMAIL_REGEX, MOBILE_REGEX, NOSPACE_REGEX, NOSPACE_REGEX_EMAIL, SubscripitonPlansOptions } from 'src/utils/constants';
import { useNavigate } from 'react-router-dom';
import { addMerchant, getCategories } from 'src/api/merchantServices';
import { tostE, tostS } from 'src/utils/Toast';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CancelIcon from '@mui/icons-material/Cancel';
import AddEditBtn from 'src/components/addEditBtn';
import { handleNumberInput } from 'src/utils/helpers';


export default function AddMerchant() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories().then((res) => {
      if (res.status === 200) {
        setCategories(res.data.data);
      }
    }).catch((err) => { console.log(err) })
  }, [])

  const validationSchema = Yup.object().shape({
    businessName: Yup.string()
      .required("Business name is required")
      .matches(NOSPACE_REGEX, "Please Enter a valid business name")
      .min(2, "Please enter at least 2 characters")
      .max(50, "Business name must not be more than 50 characters."),
    representName: Yup.string()
      .required("Representative name is required")
      .matches(NOSPACE_REGEX, "Please Enter a valid representative name")
      .min(2, "Please enter at least 2 characters")
      .max(50, "Representative name must not be more than 50 characters."),
    email: Yup.string()
      .required("Email is required")
      .email("Please enter a valid email")
      .trim()
      .matches(EMAIL_REGEX, "Please enter a valid email address"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .matches(MOBILE_REGEX, "Please Enter a valid phone number"),
    categoryIds: Yup.array().min(1, "Select at least one category").required('Please select a category'),



    paymentModeType: Yup.string().required("Subscription mode is requied"),
    sharePercentage: Yup.string().when('paymentModeType', {
      is: (paymentModeType) => paymentModeType == "revshare",
      then: () => Yup.number().required("Per Coupon Sold Fee is required").min(1, "Per Coupon Sold Fee must be greater than 0").max(100, "Per Coupon Sold Fee must be less than or equals to 100"),
      otherwise: () => Yup.string()
    }),
    subscriptionPrice: Yup.string().when('paymentModeType', {
      is: (paymentModeType) => paymentModeType == "subscription" || paymentModeType == "hybrid",
      then: () => Yup.number().required("Monthly fee is required"),
      otherwise: () => Yup.string()
    }),
    hybridPrice: Yup.string().when("paymentModeType", {
      is: (paymentModeType) => paymentModeType == "hybrid",
      then: () => Yup.number().required("Per coupon fee is required"),
      otherwise: () => Yup.string()

    })
  });

  const formik = useFormik({
    initialValues: {
      businessName: "",
      representName: "",
      email: "",
      phoneNumber: "",
      categoryIds: [],


      paymentModeType: "",
      sharePercentage: "",
      subscriptionPrice: "",
      subscriptionMonth: "1",
      hybridPrice: ""
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {

      let payload = { ...values, email: values.email?.trim() }

      if (payload.paymentModeType == "revshare") {
        delete payload.subscriptionMonth
        delete payload.subscriptionPrice
        delete payload.hybridPrice
      }
      else if (payload.paymentModeType == "subscription") {
        delete payload.sharePercentage
        delete payload.hybridPrice
      }
      else if (payload.paymentModeType == "hybrid") {
        delete payload.sharePercentage
      }


      addMerchant(payload).then((res) => {
        if (res.status == 200) {
          tostS(res.data.message)
          navigate("/merchant")
        }
      }).catch((err) => {
        console.log(err);
        if (err.response.data) {
          tostE(err.response.data.message);
        }
      }).finally(() => {
        setSubmitting(false);
      });
    },
  });

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setValues } = formik;

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setValues({ ...values, phoneNumber: value });
    }
  }

  const handleDelete = (idx) => {
    const currentVal = [...values.categoryIds];
    currentVal.splice(idx, 1);
    setValues({ ...values, categoryIds: [...currentVal] });
  }

  return (
    <Card>
      <CardContent>
        <FormikProvider value={formik} style={{ padding: "34px 30px" }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Add Merchant
          </Typography>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={12}>
                <TextField
                  name="businessName"
                  fullWidth
                  label="Business Name"
                  {...getFieldProps("businessName")}
                  error={Boolean(touched.businessName && errors.businessName)}
                  helperText={touched.businessName && errors.businessName}
                />
              </Grid>
              <Grid item lg={6} md={12}>
                <TextField
                  fullWidth
                  name="representName"
                  label="Representative Name"
                  {...getFieldProps("representName")}
                  error={Boolean(touched.representName && errors.representName)}
                  helperText={touched.representName && errors.representName}
                />
              </Grid>
              <Grid item lg={6} md={12}>
                <TextField
                  fullWidth
                  name="email"
                  label="Email"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item lg={6} md={12}>
                <TextField
                  fullWidth
                  name="phoneNumber"
                  label="Phone Number"
                  {...getFieldProps("phoneNumber")}
                  onChange={(e) => { handlePhoneChange(e) }}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />
              </Grid>
              <Grid item lg={6} md={12}>
                <FormControl fullWidth error={Boolean(touched.categoryIds && errors.categoryIds)}>
                  <InputLabel id="demo-mutiple-chip-checkbox-label">
                    Select Categories
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-chip-checkbox-label"
                    id="demo-mutiple-chip-checkbox"
                    multiple
                    label="Select Categories"
                    value={values.categoryIds}
                    onChange={(e) => {
                      const val = e.target.value;
                      setValues({ ...values, categoryIds: [...val] });
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value, idx) => (
                          <Chip
                            key={value}
                            label={categories.find(item => item.id == value)?.name}
                            clickable
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                            onDelete={() => { handleDelete(idx) }}
                            onClick={() => { handleDelete(idx) }}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {categories.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(touched.categoryIds && errors.categoryIds) && <FormHelperText>{touched.categoryIds && errors.categoryIds}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" error={formik.touched.paymentModeType && Boolean(formik.errors.paymentModeType)}>Subscription Mode</InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Subscription Mode"
                    value={formik.values.paymentModeType}
                    error={formik.touched.paymentModeType && Boolean(formik.errors.paymentModeType)}
                    helperText={formik.touched.paymentModeType && Boolean(formik.errors.paymentModeType)}
                    onChange={(e) => formik.setFieldValue("paymentModeType", e?.target.value)}
                  >
                    {SubscripitonPlansOptions?.map((sub, id) => (
                      <MenuItem value={sub?.value} key={id}>{sub?.label}</MenuItem>
                    ))}

                  </Select>
                  <FormHelperText className="error-msg">{formik?.touched?.paymentModeType && formik?.errors?.paymentModeType ? formik?.errors?.paymentModeType : ""}</FormHelperText>


                </FormControl>
              </Grid>

              {
                (formik.values.paymentModeType == "revshare") ?
                  <Grid item container xs={12} alignItems={"center"} >
                    <TextField
                      fullWidth
                      label="Per Coupon Sold Fee (%)"
                      variant="outlined"
                      {...formik.getFieldProps("sharePercentage")}
                      onChange={e => handleNumberInput(e, "sharePercentage", formik, true)}
                      error={formik.touched.sharePercentage && Boolean(formik.errors.sharePercentage)}
                      helperText={formik.touched.sharePercentage && formik.errors.sharePercentage}

                    />

                  </Grid>
                  : ""
              }

              {
                (formik.values.paymentModeType == "subscription" || formik.values.paymentModeType == "hybrid") ?
                  <>
                    <Grid item container xs={12} alignItems={"center"} >

                      <TextField
                        fullWidth
                        label="Time-frequency"
                        variant="outlined"
                        value={"1 Month"}
                        disabled

                      />
                    </Grid>
                  </>
                  : ""
              }

              {
                (formik.values.paymentModeType == "subscription") ?
                  <>
                    <Grid item container xs={12} alignItems={"center"} >

                      <TextField
                        fullWidth
                        label="Monthly Fee ($)"
                        variant="outlined"
                        {...formik.getFieldProps("subscriptionPrice")}
                        onChange={e => handleNumberInput(e, "subscriptionPrice", formik, true)}
                        error={formik.touched.subscriptionPrice && Boolean(formik.errors.subscriptionPrice)}
                        helperText={formik.touched.subscriptionPrice && formik.errors.subscriptionPrice}

                      />
                    </Grid>
                  </>
                  : ""
              }


              {
                (formik.values.paymentModeType == "hybrid") ?
                  <>
                    <Grid item container xs={6} alignItems={"center"} >

                      <TextField
                        fullWidth
                        label="Monthly Fee ($)"
                        variant="outlined"
                        {...formik.getFieldProps("subscriptionPrice")}
                        onChange={e => handleNumberInput(e, "subscriptionPrice", formik, true)}
                        error={formik.touched.subscriptionPrice && Boolean(formik.errors.subscriptionPrice)}
                        helperText={formik.touched.subscriptionPrice && formik.errors.subscriptionPrice}
                      />
                    </Grid>

                    <Grid item container xs={6} alignItems={"center"} >
                      <TextField
                        fullWidth
                        label="Per Coupon Sold Fee ($)"
                        variant="outlined"
                        {...formik.getFieldProps("hybridPrice")}
                        onChange={e => handleNumberInput(e, "hybridPrice", formik)}
                        error={formik.touched.hybridPrice && Boolean(formik.errors.hybridPrice)}
                        helperText={formik.touched.hybridPrice && formik.errors.hybridPrice}

                      />
                    </Grid>
                  </>
                  : ""
              }

            </Grid>

            <AddEditBtn isSubmitting={isSubmitting} navigateUrl={"/merchant"} />
            {/* <Button
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting.toString()}
              sx={{ my: 2 }}
            >
              Save
            </Button>
            <Button
              size="large"
              variant="contained"
              onClick={() => {

                navigate("/merchant");
              }}
              sx={{ my: 2, ml: 4 }}
            >
              Cancel
            </Button> */}
          </Form>
        </FormikProvider>
      </CardContent>
    </Card >
  )
}
