import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Card, CardContent, CardHeader, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import { useNavigate } from 'react-router-dom';
import { feedbackServices } from 'src/api/feedbackService';
import CustomLoader from 'src/components/CustomLoader';
import SearchBtn from 'src/components/SearchBtn';
import errorHandler from 'src/utils/errorHandler';
import { formatDate, getSrno } from 'src/utils/helpers';
import FeedbackView from './View';


const FeedbackListing = () => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [paginData, setPaginData] = useState({
        data: [],
        totalCount: 0,
    })
    const [payload, setPayload] = useState({
        pageSize: 5,
        pageNumber: 1,
        searchItem: ""
    })
    const [viewModal, setViewModal] = useState({
        show: false,
        id: ""
    })


    const fetchFeedback = async () => {
        try {
            setLoading(true)
            let res = await feedbackServices.getAllFeedback(payload)
            setPaginData({
                data: res?.data?.data?.result,
                totalCount: res?.data?.data?.totalRecords
            })

        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        {
            name: "Sr. No.",
            selector: (row, index) => getSrno(payload.pageNumber, payload.pageSize, index),
            grow: 0.5
        },
        {
            name: "Feedback Id",
            selector: (row) => row?.id
        },

        {
            name: "Merchant Business Name",
            selector: (row) => row?.businessName,
            grow: 1.5
        },
        {
            name: "User Name",
            selector: (row) => row?.fullName
        },
        {
            name: "User Email",
            selector: (row) => row?.email
        },
        {
            name: "User Phone Number",
            selector: (row) => row?.phoneNumber
        },
        {
            name: "User Descripiton",
            selector: (row) => row?.description
        },
        {
            name: "User Rating",
            selector: (row) => row?.rating
        },
        // {
        //     name: "Status",
        //     selector: (row) => row?.status == "1" ? "Active" : "Inactive"
        // },
        {
            name: "Created At",
            selector: (row) => formatDate(row?.createdAt)
        },

        {
            name: <div >Action</div>,
            cell: (row) => (
                <>
                    <Tooltip title="View" placement="top">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setViewModal({
                                    show: true,
                                    id: row.id
                                })
                            }}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ]

    useEffect(() => {
        fetchFeedback()
    }, [payload])

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{ variant: "h4" }}
                    title="Feedback Management"
                ></CardHeader>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    <SearchBtn payload={payload} setPayload={setPayload} />

                </Box>

                <CardContent>
                    <DataTable
                        columns={columns}
                        data={paginData.data}
                        pagination
                        paginationServer
                        paginationTotalRows={paginData.totalCount}
                        onChangePage={(page) => setPayload({ ...payload, pageNumber: page })}
                        paginationPerPage={payload.pageSize}
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                        // paginationTotalRows={data ? data.length : 0}
                        paginationRowsPerPageOptions={[5, 10, 20, 50]}
                        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                            setPayload({ ...payload, pageSize: currentRowsPerPage, pageNumber: currentPage });
                        }}
                    />
                </CardContent>
            </Card>

            <FeedbackView viewModal={viewModal} setViewModal={setViewModal} />
        </>
    )
}

export default FeedbackListing