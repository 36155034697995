import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ReportServices } from 'src/api/reportServices'
import CustomLoader from 'src/components/CustomLoader'
import DateRangePickerComp from 'src/components/DateRangePickerComp'
import DownloadExcelBtn from 'src/components/DownloadExcelBtn'
import SearchBtn from 'src/components/SearchBtn'
import AxiosInterceptor from 'src/utils/AxiosInterceptor'
import errorHandler from 'src/utils/errorHandler'

const Report = ({ title, apiUrl, getColumns, excelData, sheetName }) => {
    const [loading, setLoading] = useState(false)
    const [paginData, setPaginData] = useState({
        data: [],
        totalCount: 0,
    })
    const [payload, setPayload] = useState({
        pageSize: 5,
        pageNumber: 1,
        searchItem: "",
    })
    const [sort, setSort] = useState({
        sortBy: "",
        asc: ""   // acs, desc
    })
    const [dateRange, setDateRange] = useState([null, null]);


    const fetchReport = async () => {
        try {
            setLoading(true)
            payload.sortBy = sort.sortBy;
            payload.sortOrder = sort.asc;
            if (dateRange[0] && dateRange[1]) {
                payload.fromDate = moment(dateRange[0])?.startOf("day")?._d?.toISOString()
                payload.toDate = moment(dateRange[1])?.endOf("day")?._d?.toISOString()
            } else {
                payload.fromDate = ""
                payload.toDate = ""

            }

            const res = await AxiosInterceptor().post(apiUrl, payload)
            setPaginData({
                data: res?.data?.data?.result,
                totalCount: res?.data?.data?.totalRecords
            })
        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchReport()
    }, [payload, sort, dateRange[1]])



    const handleSort = (e, sortDirection) => {
        setSort({
            sortBy: e.sortField,
            asc: sortDirection
        })
    }

    return (
        <>
            <Card className='listing_card'>
                <CardHeader
                    title={title}
                    titleTypographyProps={{ variant: "h4" }}
                />
                <Grid container display={"flex"} justifyContent={"flex-end"} alignItems={"end"} gap={2} >
                    <DateRangePickerComp dateRange={dateRange} setDateRange={setDateRange} payload={payload} setPayload={setPayload} />
                    <SearchBtn payload={payload} setPayload={setPayload} />
                    <DownloadExcelBtn data={paginData?.data} excelData={excelData} sheetName={sheetName} />

                </Grid>
                <CardContent>
                    <DataTable
                        columns={getColumns(payload?.pageNumber, payload?.pageSize)}
                        data={paginData.data}
                        pagination
                        paginationServer
                        paginationDefaultPage={payload.pageNumber}
                        paginationPerPage={payload.pageSize}
                        paginationTotalRows={paginData.totalCount}
                        onChangePage={(page) => setPayload({ ...payload, pageNumber: page })}
                        paginationRowsPerPageOptions={[5, 10, 20, 50]}
                        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                            setPayload({ ...payload, pageSize: currentRowsPerPage, pageNumber: currentPage });
                        }}
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                        sortServer
                        onSort={handleSort}

                    />

                </CardContent>
            </Card>
        </>
    )
}

export default Report