import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import EventIcon from '@mui/icons-material/Event';


const DateRangePickerComp = ({ dateRange, setDateRange, payload, setPayload }) => {
    const [startDate, endDate] = dateRange;


    return (
        <div className={`custom_datepicker ${startDate ? "" : "noDateSelected"}`}>

            <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}

                dateFormat={"MM/dd/yyyy"}
                placeholderText="Search By Date"
                onChange={(update) => {
                    if (update.every((uDate) => uDate != null) || update.every((uDate) => uDate == null)) {
                        setPayload({ ...payload, pageNumber: 1 })
                    }
                    setDateRange(update);


                }}
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
                monthsShown={window.screen.width > 650 ? 2 : 1}
                isClearable={true}
            />
            <span className="calendar_cust_icon">

                {!startDate && !endDate ? <EventIcon /> : ""}
            </span>
        </div>

    )
}

export default DateRangePickerComp