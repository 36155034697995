import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import categoryService from "src/api/categoryService";
import { getMerchantDetail } from "src/api/merchantServices";
import { Loader } from "src/components/CustomLoader";
import { SubscripitonPlansOptions } from "src/utils/constants";
import errorHandler from "src/utils/errorHandler";
import { capitalize } from "src/utils/helpers";

const ViewMerchant = ({ id, show, onHide }) => {
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(true)


    const userData = async () => {
        try {
            setLoading(true)
            const response = await getMerchantDetail(id);
            if (response.status === 200) {
                setData(response.data.data);
            }
        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }

    };

    useEffect(() => {
        userData();
    }, []);

    const showAddress = (data) => {
        if (!data) return "";

        const { address, address2, city, zipCode } = data;
        const parts = [address, address2, city, zipCode].filter(Boolean);

        return parts.length > 0 ? parts.join(", ") : "";
    };


    return (
        <Dialog maxWidth="md" fullWidth={true} open={show} onClose={onHide}>
            <DialogTitle as="h2">Details</DialogTitle>
            {loading ? <Loader /> :
                <DialogContent dividers>
                    <Grid container spacing={2} sx={{ overflowWrap: "anywhere" }}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Business Name</Typography>
                            <DialogContentText>
                                {capitalize(data?.businessName)}{" "}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Representative Name</Typography>
                            <DialogContentText>
                                {capitalize(data?.representName)}{" "}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Email</Typography>
                            <DialogContentText>
                                {capitalize(data?.email)}{" "}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Phone Number</Typography>
                            <DialogContentText>
                                {capitalize(data?.phoneNumber)}{" "}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Address</Typography>
                            <DialogContentText>
                                {showAddress(data)}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Categories</Typography>
                            <DialogContentText>
                                {data?.categoryIds && data?.categoryIds.map(item => item.name).join(", ")}
                            </DialogContentText>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Subscripton Mode</Typography>
                            <DialogContentText>
                                {SubscripitonPlansOptions?.filter(plan => plan?.value == data?.paymentModeType)?.[0]?.label}
                            </DialogContentText>
                        </Grid>

                        {data?.paymentModeType == "revshare"
                            ? <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Per Coupon Sold Fee</Typography>
                                <DialogContentText>
                                    {data?.sharePercentage}%
                                </DialogContentText>
                            </Grid>
                            : ""
                        }

                        {data?.paymentModeType == "subscription"
                            ? <>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle1">Time-frequency</Typography>
                                    <DialogContentText>
                                        {data?.subscriptionMonth} Month
                                    </DialogContentText>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle1">Monthly Fee</Typography>
                                    <DialogContentText>
                                        ${data?.subscriptionPrice}
                                    </DialogContentText>
                                </Grid>
                            </>
                            : ""
                        }

                        {data?.paymentModeType == "hybrid"
                            ? <>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle1">Time-frequency</Typography>
                                    <DialogContentText>
                                        {data?.subscriptionMonth} Month
                                    </DialogContentText>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle1">Monthly Fee</Typography>
                                    <DialogContentText>
                                        ${data?.subscriptionPrice}
                                    </DialogContentText>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle1">Per Coupon Sold Fee</Typography>
                                    <DialogContentText>
                                        ${data?.hybridPrice}
                                    </DialogContentText>
                                </Grid>
                            </>
                            : ""
                        }


                    </Grid>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => onHide()}
                            type="submit"
                            style={{ backgroundColor: "white" }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </DialogContent>
            }
        </Dialog>
    );
};

export default ViewMerchant;
