import React, { useEffect, useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";
import errorHandler from 'src/utils/errorHandler';
import { feedbackServices } from 'src/api/feedbackService';
import { Loader } from 'src/components/CustomLoader';
import { formatDate } from 'src/utils/helpers';


const FeedbackView = ({ viewModal, setViewModal }) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true)

    const feedbackData = async (id) => {
        try {
            setLoading(true)
            const response = await feedbackServices.getSingleFeedback(id);
            setData(response.data.data);
        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        if (viewModal.id)
            feedbackData(viewModal.id);
    }, [viewModal.id]);

    const onHide = () => {
        setViewModal({
            show: false,
            id: ""
        })
    }

    return (
        <Dialog maxWidth="md" fullWidth={true} open={viewModal?.show} onClose={onHide}>
            <DialogTitle as="h2">Details</DialogTitle>
            {loading ? <Loader /> :
                <DialogContent dividers>
                    <Grid container spacing={2} sx={{ overflowWrap: "anywhere" }}>
                        <Grid item xs={12} md={6}>

                            <Typography variant="subtitle1">Merchant Business Name</Typography>
                            <DialogContentText>
                                {data?.businessName}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>

                            <Typography variant="subtitle1">User Name</Typography>
                            <DialogContentText>
                                {data?.fullName}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">User Email</Typography>
                            <DialogContentText>
                                {data?.email}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">User Rating</Typography>
                            <DialogContentText>
                                {data?.rating}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Coupon Id</Typography>
                            <DialogContentText>
                                {data?.couponId}
                            </DialogContentText>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Created At</Typography>
                            <DialogContentText>
                                {formatDate(data?.createdAt)}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">User Description</Typography>
                            <DialogContentText>
                                {(data?.description)}
                            </DialogContentText>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => onHide()}
                            type="submit"
                            style={{ backgroundColor: "white" }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </DialogContent>
            }
        </Dialog>
    )
}

export default FeedbackView