import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import apiUsers from 'src/api/usersService';
import { Loader } from 'src/components/CustomLoader';
import { CurrentDateFormat, CurrentDateFormatWithTime, FILE_URL } from 'src/utils/constants';
import errorHandler from 'src/utils/errorHandler';
import { capitalize, formatDate } from 'src/utils/helpers';


const ViewUserDetail = ({ id, show, onHide }) => {
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(true)

    const userData = async () => {
        try {
            setLoading(true)
            const response = await apiUsers.getOneUser(id);
            setData(response.data.data);
        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        userData()
    }, [])

    return (

        <Dialog maxWidth="md" fullWidth={true} open={show} onClose={onHide}>
            <DialogTitle as='h2'>Details</DialogTitle>
            {loading ? <Loader /> :
                <DialogContent dividers>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Profile Image</Typography>
                            <Avatar
                                style={{ cursor: (data?.profileImage ? "pointer" : "") }}
                                alt={data?.firstName}
                                src={FILE_URL + data?.profileImage}
                                onClick={() => { if (data?.profileImage) { window.open((FILE_URL + data?.profileImage), "_blank") } }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Full Name</Typography>
                            <DialogContentText>{capitalize(data?.fullName)}</DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Email</Typography>
                            <DialogContentText>{capitalize(data?.email)}</DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Mobile Number</Typography>
                            <DialogContentText>{data?.phoneNumber}</DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Gender</Typography>
                            <DialogContentText>{data?.gender}</DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Date of Birth</Typography>
                            <DialogContentText>{data?.dob ? moment(data?.dob).format(CurrentDateFormat) : "-"}</DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Address</Typography>
                            <DialogContentText sx={{ wordBreak: "break-all" }}>{data?.address ? data?.address : "-"}</DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Modified At</Typography>
                            <DialogContentText sx={{ wordBreak: "break-all" }}>{formatDate(data?.updatedAt, CurrentDateFormatWithTime)}</DialogContentText>
                            {/* <DialogContentText sx={{ wordBreak: "break-all" }}>{data?.updatedAt ? moment(data?.updatedAt).format("DD-MMM-yyyy hh:mm a") : "-"}</DialogContentText> */}
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => onHide()} type="submit" style={{ backgroundColor: 'white' }} >
                            Close
                        </Button>
                    </DialogActions>
                </DialogContent>
            }
        </Dialog>
    )
}

export default ViewUserDetail