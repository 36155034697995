// component
import SvgColor from '../../../components/svg-color';
import QuizIcon from "@mui/icons-material/Quiz";
import PolicyIcon from "@mui/icons-material/Policy";
import CategoryIcon from "@mui/icons-material/Category";
import StorefrontIcon from '@mui/icons-material/Storefront';
import EmailIcon from '@mui/icons-material/Email';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DiscountIcon from '@mui/icons-material/Discount';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssessmentIcon from '@mui/icons-material/Assessment';
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: icon("ic_analytics"),
  },
  {
    title: "User Management",
    path: "/user",
    icon: icon("user_management"),
  },
  {
    title: "Deleted User Management",
    path: "/deleted-user",
    icon: icon("user_management"),
  },
  {
    title: "Merchant Management",
    path: "/merchant",
    icon: <StorefrontIcon />,
  },
  {
    title: "Merchant Payout Management",
    path: "/merchant-payout",
    icon: <AccountBalanceIcon />
  },
  {
    title: "Category Management",
    path: "/category",
    icon: <CategoryIcon />,
  },
  {
    title: "Coupon Management",
    icon: <DiscountIcon />,
    children: [
      {
        title: "Coupon Templates",
        path: "/coupon-templates",
        icon: icon("cms"),
      },
      {
        title: "Coupon Published",
        path: "/coupon-published",
        icon: <DiscountIcon />,
      },


    ],
  },

  {
    title: "Email Template Management",
    path: "/email-template",
    icon: <EmailIcon />,
  },

  {
    title: "Notification Management",
    path: "/notification-management",
    icon: <NotificationsIcon />,
  },
  {
    title: "Complaint Management",
    path: "/complaint-management",
    icon: <ListAltIcon />,
  },
  {
    title: "Payment History Management",
    path: "/payment-history-management",
    icon: <AccountBalanceIcon />,
  },
  {
    title: "Feedback management",
    path: "/feedback",
    icon: <FeedbackIcon />,
  },
  {
    title: "Report Management",
    icon: <AssessmentIcon />,
    children: [
      {
        title: "User Register Report",
        path: "/user-register-report",
        icon: <AssessmentIcon />,
      },
      {
        title: "Merchant Register Report",
        path: "/merchant-register-report",
        icon: <AssessmentIcon />,
      },
      {
        title: "Coupon Purchased Report",
        path: "/coupon-purchased-report",
        icon: <AssessmentIcon />,
      },
      {
        title: "Revenue Report",
        path: "/revenue-report",
        icon: <AssessmentIcon />,
      },


    ],
  },
  {
    title: "CMS Management",
    icon: icon("cms"),
    children: [
      {
        title: "FAQ Management",
        path: "/faq",
        icon: <QuizIcon />,
      },
      {
        title: "User Privacy Policy",
        path: "/cms/user-privacy-policy",
        icon: <PolicyIcon />,
      },
      {
        title: "Merchant Privacy Policy",
        path: "/cms/merchant-privacy-policy",
        icon: <PolicyIcon />,
      },
      {
        title: "User Delete Account Policy",
        path: "/cms/user-delete-account-policy",
        icon: <PolicyIcon />,
      },

      {
        title: "User Terms & Conditions",
        path: "/cms/user-terms-conditions",
        icon: icon("cms"),
      },
      {
        title: "Merchant Terms & Conditions",
        path: "/cms/merchant-terms-conditions",
        icon: icon("cms"),
      },
      {
        title: "Registration Agreement",
        path: "/cms/reg-agreement",
        icon: icon("cms"),
      },
      {
        title: "Fine Print",
        path: "/cms/fine-print",
        icon: icon("cms"),
      },
      {
        title: "Revenue Share Terms",
        path: "/cms/revenue-share-terms",
        icon: icon("cms"),
      },
      {
        title: "Subscription Terms",
        path: "/cms/subscription-terms",
        icon: icon("cms"),
      },
      {
        title: "Hybrid Terms",
        path: "/cms/hybrid-terms",
        icon: icon("cms"),
      },




    ],
  }
];

export default navConfig;
