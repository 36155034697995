import { Button, Paper } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import React from 'react'

const ConfirmModal = ({ show, setShow, msg, handleSwitch, loading, }) => {

    const handleClose = () => {
        setShow(false)
    }





    return (
        <Paper>
            <Dialog open={show} onClose={handleClose} aria-describedby="alert-dialog-slide-description" >
                <DialogTitle as="h2">{msg}</DialogTitle>

                <DialogActions>
                    <Button
                        variant="outlined"
                        style={{ backgroundColor: "white" }}
                        slotProps={{ backdrop: { sx: { background: 'rgba(255, 255, 255, 0.25)' } } }}
                        onClick={() => !loading && handleSwitch()}
                    >
                        Yes
                    </Button>
                    <Button variant="outlined" style={{ backgroundColor: "white" }} onClick={handleClose}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>

    )
}

export default ConfirmModal